import { useEffect, useState } from 'react';

import { ImagesMap } from '../Types';

function preloadImagePromise(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Try without CORS first
    const tryLoad = () => {
      img.onload = () => resolve(img);
      img.onerror = () => {
        // If failed, try with CORS
        img.crossOrigin = 'anonymous';
        img.src = `${src}?${new Date().getTime()}`; // Add cache buster
      };
      img.src = src;
    };

    // Set up final error handler
    img.onerror = img.onabort = () => {
      console.warn(`Failed to load image: ${src}`);
      reject();
    };

    tryLoad();
  });
}

async function getImageAsync(imgUrl: string) {
  let image: HTMLImageElement | null = null;
  try {
    image = await preloadImagePromise(imgUrl);
  } catch (error) {
    // Silent fail - we'll handle missing images gracefully
  }
  return image;
}

export function useImagePreloader(imageList: string[]) {
  const [images, setImages] = useState<ImagesMap>({});

  useEffect(() => {
    const loadedImages: ImagesMap = {};
    let mounted = true;

    const preloadImages = async () => {
      const imagePromises = imageList
        .filter((imgUrl) => imgUrl && !images[imgUrl])
        .map(async (imgUrl) => {
          const image = await getImageAsync(imgUrl);
          if (image && mounted) {
            loadedImages[imgUrl] = image;
          }
        });

      await Promise.all(imagePromises);

      if (mounted && Object.keys(loadedImages).length > 0) {
        setImages((prev) => ({
          ...prev,
          ...loadedImages,
        }));
      }
    };

    if (imageList?.length) {
      preloadImages();
    }

    return () => {
      mounted = false;
    };
  }, [imageList]);

  return images;
}
