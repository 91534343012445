import React, { ComponentType, LazyExoticComponent } from 'react';

export type ComponentWithPreload<T extends ComponentType<any>> = LazyExoticComponent<T> & {
  preload: () => Promise<{ default: T }>;
};

export function lazyPreload<T extends ComponentType<any>>(
  importStatement: () => Promise<{ default: T }>
): ComponentWithPreload<T> {
  const Component = React.lazy(importStatement);
  const componentWithPreload = { ...Component, preload: importStatement };
  return componentWithPreload as ComponentWithPreload<T>;
}

export function preloadModule(moduleImport: () => Promise<any>) {
  const promise = moduleImport();
  if ('cache' in promise && typeof (promise as any).cache === 'function') {
    (promise as any).cache();
  }
}

export function preloadModules(modules: (() => Promise<any>)[]) {
  modules.forEach(preloadModule);
}
