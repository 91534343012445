import { LazyMap2d, LazyMap3d, LazySwapWidget } from './exports';
import { usePreloadModules } from './usePreloadModules';

function LazyModulesPreloading() {
  usePreloadModules([
    () => import('../pages/HomePage/MapContainer/Map/Map2d/Map2d'),
    () => import('../pages/HomePage/MapContainer/Map/Map3d/Map3d'),
    () => import('../pages/SwapPage/SwapWidget/SwapWidget'),
  ]);
  return null;
}

export default LazyModulesPreloading;
